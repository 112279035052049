export const useAllTagsStore = defineStore("allTagsStore", {
    state: () => ({
        tags: null,
    }),
    getters: {
        getAllTags() {
            return this.tags;
        },
    },
    actions: {
        setAllTags(info) {
            this.tags = info
        },
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAllTagsStore, import.meta.hot));
}