<template>
    <div class="vendor_logo_list">
        <!--components/LogoList.vue-->
        <div v-for="(tag, index) in short_list" :key="index" >
            <div class="vendor_logo_row" v-if="(get_tag_count(tag) > 0) && (! isJavascriptString(tag,'title'))">
                <div @click.prevent.stop="navigateTo(`/?tag=${tag}`)" class="vendor_logo_span clickable" :style="'background: no-repeat center/contain url(' + get_vendor_logo(tag) + ')'">&nbsp;</div>
                <div>
                    <span class="clickable" @click.prevent.stop="navigateTo(`/?tag=${getTagAliases(tag).join(',')}`)">{{ get_vendor_name(tag) }} ({{ get_tag_count(tag) }})</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
let logo_list = getStaticLogoList();

let all_tags_we_need = {};

let short_list = getLogoShortList();
let tag_counts = {};

const get_vendor_logo = (tag) => {
    let logo = '';
    if (logo_list.hasOwnProperty(tag.toLowerCase())) {
        logo = logo_list[tag.toLowerCase()]['logo'];
    }
    return logo;
}

const get_vendor_name = (tag) => {
    let label = '';
    if (logo_list.hasOwnProperty(tag.toLowerCase())) {
        label = logo_list[tag.toLowerCase()]['display_label'];
    } else {
        label = tag;
    }
    return label;
}

const get_tag_count = (tag) => {
    if (! tag_counts.hasOwnProperty(tag)) {
        let total_count = 0;
        let aliases = getTagAliases(tag.toLowerCase());
        aliases.forEach((alias) => {
            Object.keys(all_tags_we_need).forEach((t) => {
                if (t.toLowerCase() == alias.toLowerCase()) {
                    total_count = total_count + all_tags_we_need[t];
                }
            });
        });
        tag_counts[tag] = total_count;
    }
    return tag_counts[tag];
}

// Create a list of aliases for all the tags that are listed in the short_list
let short_list_aliases = [];
short_list.forEach((tag) => {
    let aliases = getTagAliases(tag.toLowerCase());
    aliases.forEach((alias) => {
        short_list_aliases.push(alias);
    });
});

let space_dropdown_state = useSpaceDropdownState();

/*
// We may have a lot of tags in our system, and we may not want to display 
// them all in this component.  Therefore, here, we filter out the tags 
// that are not in the short_list.
*/
let all_tags_store = useAllTagsStore();
if (all_tags_store.getAllTags == null) {
    let all_tags = await getAllTags('', space_dropdown_state.value);
    all_tags_store.setAllTags(all_tags);
}

let all_tags_full = all_tags_store.getAllTags;
let keys = Object.keys(all_tags_full);
keys.forEach((k) => {
    if (short_list_aliases.includes(k.toLowerCase())) {
        all_tags_we_need[k] = all_tags_full[k];
    } else {
        all_tags_we_need[k] = all_tags_full[k];
    }
    get_tag_count(k);
});

/*
Initially, we only want to display tags that we have logo for, but then a customer want us to 
display tags that we do not have logo for as well.  Here we sort by the count using descending 
order but perhaps we should not sort it descending by count, but sort alphabetically.
If we want to sort alphabetically, we just have to call keys.sort without providing a sort function?
*/
keys = Object.keys(tag_counts);
let keys_sorted = keys.sort((a,b) => {
    return tag_counts[b] - tag_counts[a]
})
for (let i = 0; i < keys_sorted.length; i++) {
    let tag = keys_sorted[i];
    if (!short_list.includes(tag.toLowerCase())) {
        short_list.push(tag);
    }
}

</script>

<style scoped>
.vendor_logo_row {
    display: flex;
    background-color: #eee;
    margin-bottom: 3px;
    padding: 5px 10px;
    border-radius: 3px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
}
.vendor_logo_row div {
    align-self: center;
}
.vendor_logo_list {
    margin: 50px 10px;
}
</style>