export const getTagAliases = (tag) => {
    /*
    Inside getStaticLogoList, we define a dictionary of vendor.  The key inside this dictionary are 
    short names such as aws, azure.

    This function take a tag, and returns an array of aliases / synonyms
    */
    let mapping = getTagMapping();

    if (mapping.hasOwnProperty(tag)) {
        return mapping[tag]
    } else {
        return [tag];
    }
}